import React, { useState } from 'react'
import {
  Menu as HamburgerIcon,
  X as CloseMenuIcon,
} from 'react-feather'
import SocialIcons from './SocialIcons'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Logo from '../../../images/svg/logo.svg'

export const menuItems = [
  {
    to: '/',
    text: 'Home',
  },
  {
    to: '../../filmography',
    text: 'Filmography',
  },
  {
    to: '../../advertising',
    text: 'Advertising',
  },
  {
    to: '../../about',
    text: 'About',
  },
  {
    to: '../../contact',
    text: 'Contact',
  },
]

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 90px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in;
  position: fixed;
  top: 0;
  z-index: 4;
  background-color: white;
  @media screen and (min-width: 1080px) {
    position: relative;
    display: flex;
    flex-direction: row;
  }
  &.menuIsToggled {
    max-height: 900px;
    transition: all 0.3s ease-in;
  }
`

const StyledLink = styled(Link)`
  align-items: center;
  padding: 20px;
  margin: 10px;
  color: var(--black);
  align-self: flex-start;
  width: 200px;
`

const Hamburger = styled(HamburgerIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  padding: 10px;
  margin: 10px;
  border-radius: 100px;
  background-color: var(--light-grey-transparent);
  @media screen and (min-width: 1080px) {
    display: none;
  }
`

const CloseMenu = styled(CloseMenuIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  padding: 10px;
  margin: 10px;
  border-radius: 100px;
  background-color: var(--light-grey-transparent);
  @media screen and (min-width: 1080px) {
    display: none;
  }
`

const NavItems = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-left: 0;
  @media screen and (min-width: 1080px) {
    display: flex;
    flex-direction: row;
  }

  li {
    list-style-type: none;
  }

  a {
    padding: 20px;
    margin: 10px;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--black);
    position: relative;
    display: block;
  }

  a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--lipstick);
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    opacity: 1;
    transform: scaleX(0);
    transform-origin: center;
  }

  a:hover::after,
  a:focus::after {
    opacity: 1;
    transform: translate3d(0, 2px, 0);
    transform: scaleX(1);
  }
`

const Layout = (props) => {
  const [menuIsToggled, setMenuIsToggled] = useState(false)

  const menuToggleHandler = (event) => {
    event.preventDefault()

    setMenuIsToggled((toggled) => !toggled)
  }

  return (
    <Nav
      menuIsToggled={menuIsToggled}
      className={menuIsToggled ? 'menuIsToggled' : ''}
    >
      <StyledLink to="/">
        <img alt="Katia Ehlert" src={Logo}></img>
      </StyledLink>
      <NavItems>
        
        {menuItems.map((item) => {
          return (
            <li key={item.text}>
              <Link to={item.to}>{item.text}</Link>
            </li>
          )
        })}
      </NavItems>
      <SocialIcons />
      {!menuIsToggled && <Hamburger onClick={menuToggleHandler} />}
      {menuIsToggled && <CloseMenu onClick={menuToggleHandler} />}
    </Nav>
  )
}

export default Layout
