import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { menuItems } from '../Header/NavBar'
import SocialIcons from '../Header/SocialIcons'

const FooterContainer = styled.footer`
  width: 100%;
  box-sizing: border-box;
`

const Grid = styled.div`
  width: 100%;
  margin: 80px auto;
  max-width: 1080px;
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 20px;
  box-sizing: border-box;

  @media screen and (min-width: 780px) {
    grid-template-columns: 1fr 1fr;

  }

  @media screen and (min-width: 1080px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding-left: 0;
  }

  ul {
    padding-left: 0;
  }

  ul li {
    list-style-type: none;
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 1.414rem;
    letter-spacing: 0.03em;
  }

  a {
    text-transform: uppercase;
    color: var(--black);
    display: block;
    font-size: 0.7rem;

    @media screen and (min-width: 780px) {
      font-size: 1rem;
    }
    
  }

  a:visited {
    color: var(--black)
  }

  a::after {
    content: '';
    position: relative;
    display: block;
    bottom: 0;
    left: 0;
    width: 30%;
    height: 2px;
    background-color: var(--lipstick);
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    opacity: 1;
    transform: scaleX(0);
    transform-origin: left;
  }

  a:hover::after,
  a:focus::after {
    opacity: 1;
    transform: translate3d(0, 2px, 0);
    transform: scaleX(1);
  }

  h4 {
    margin-top: 0;
  }
  
`

const BottomBar = styled.div`
  background-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  min-height: 40px;
  font-size: 14px;
`

const Footer = (props) => {
  return (
    <FooterContainer>
      <Grid>
        <div>
          <h4>Links</h4>
          <ul>
            {menuItems.map(link => {
              return <li key={link.text}><Link to={link.to}>{link.text}</Link></li>
            })}
          </ul>
        </div>
        <div>
        <h4>Contact</h4>
          <ul>
            <li><a href='mailto:katia.ehlert@gmail.com'>katia.ehlert@gmail.com</a></li>
          </ul>
        </div>
        <div>
        <h4>Social</h4>
        <SocialIcons />
        </div>
      </Grid>
      <BottomBar>Copyright Katia Ehlert 2021 &copy;</BottomBar>
    </FooterContainer>
  )
}

export default Footer
