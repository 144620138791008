import React from 'react'
import styled from 'styled-components'
import Imdb from '../../../images/imdb.png'
import { Instagram } from 'react-feather'

const SocialIconsWrapper = styled.div`
  display: flex;
  margin-right: 10px;
  margin-bottom: 20px;

  @media screen and (min-width: 1080px) {
    margin-bottom: 0;
  }

  a {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 0 10px 0 0;
    border-radius: 100px;
    background-color: var(--light-grey-transparent);
    font-size: 0!important;
  }
  a:hover,
  a:focus {
    background-color: var(--lipstick);
  }

  a:hover svg,
  a:focus svg {
    stroke: white;
  }
  a::after { 
      display: none !important;
  }
`

const SocialIcons = () => {
  return (
    <SocialIconsWrapper>
      <a
        href="https://instagram.com/katia_en_camerinos/"
        target="_blank"
        rel="noreferrer"
      >
        <Instagram size="24px" color="black" strokeWidth="1.6" />
      </a>
      <a
        href="https://www.imdb.com/name/nm8269272/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={Imdb}
          height="24px"
          width="24px"
          alt="Katia Ehlert Imdb page"
        />
      </a>
    </SocialIconsWrapper>
  )
}

export default SocialIcons
