import React from 'react'
import '../../global/global.css'
import NavBar from './Header/NavBar'
import Footer from './Footer/Footer'
import styled from 'styled-components'

const Main = styled.main`
  margin-top: 0;

  @media screen and (max-width: 1080px) {
    margin-top: 90px;
  }
`

const Layout = (props) => {
  return (
    <>
      <NavBar />
      <Main>{props.children}</Main>
      <Footer />
    </>
  )
}

export default Layout
